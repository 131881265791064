$A: "Audiowide", cursive;
$RCond: "Roboto Condensed", sans-serif;
$RSlab: "Roboto Slab", serif;
$darkHover: #1918239f;
$darkRedGradient: linear-gradient(to bottom, #ff2929 0%, #ffbc6b 100%);
$darkBlueGradient: linear-gradient(to bottom, #00b7ff 0%, #5be5b7 70%, #57d15d 100%);
$darkPinkGradient: linear-gradient(to bottom, #6a01ff 0%, #f2b0ff 100%);
$darkBg: #191823;
$lightBg: linear-gradient(to bottom, #6a01ff 0%, #f2b0ff 100%);
$halfDarkBg: #26263d;
$lightBg: linear-gradient(to bottom, #ededed83 30%, #b8b8b8 100%);
&.light-green {
  background: $lightBg;
  .topBar {
    .topBar-options-container {
      .title {
        color: #fff;
      }
    }
  }
  .previewFile {
    a {
      text-decoration: none;
    }
    .container {
      padding: 0 20px;
    }
    .header {
      background: $halfDarkBg;
      border-bottom: 1px solid $darkBg;
      .logo {
        h5 {
          color: #fff;
        }
        h6 {
          color: #bdbdbd;
        }
      }
      .back {
        .defaultBtn {
          span {
            color: #fff;
          }
        }
      }
    }
    .item {
      background: $halfDarkBg;
      color: #fff;
      .head {
        border-bottom: 1px solid #616161;
      }
      .body {
        .btn {
          background: linear-gradient(to bottom, #ffae00 0%, #f1673e 100%);
          span {
            color: #fff;
          }
        }
      }
    }
  }
  .mainLayout {
    background: $lightBg;
    color: #232323;
    .cards {
      .cards__item {
        background: #fff;
        color: #000;
        .cards__content {
          border-top: 1px solid #616161;
          .cards__info {
            .cards__btns {
              .cards__btn {
                background: #f3e5f5;
              }
            }
          }
        }
      }
    }
    .defaultBtn {
      background: #26c6da;
      @include defBtn;
    }
    .topBar {
      background: #00bcd4;
    }
    .sideMenu {
      .sideMenu__arrow {
        background: linear-gradient(to bottom, #8eda8b83 30%, #b8b8b8 100%);
      }
      .sideMenu-itemLink {
        &.active {
          background: #4db6ac;
        }
        &:hover {
          background: #4db6ac;
        }
      }
      .sideMenu-listContact {
        .sideMenu-listContact-name {
          &.active {
            background: #4db6ac;
          }
          &:hover {
            background: #4db6ac;
          }
        }
      }
    }
    .profileContent {
      .profileContent__box {
        background: #fff;
        &_company {
          background: #fff;
        }
        .profileContent__btn {
          color: #000;
        }
      }
    }
    .companyList {
      .companyList__wrap {
        .companyList__item {
          .companyList__create {
            background: #00bcd4;
            border: none;
          }
        }
      }
    }
    .contactsContent-wrapper {
      .table {
        .head {
          background: #f7f6f6;
        }
        .body {
          background: #fff;
          .row {
            .cell {
              .toPatients {
                background: #f3e5f5;
              }
            }
          }
        }
      }
    }
  }

  .dialog-btn {
    background: #ff6f00;
  }
  .dialog-default {
    .dialog__wrap {
      .dialog-defaultTitle {
        background: #00acc1;
        color: #fff;
      }
    }
  }
}
