@import "./assets/css/reset.css";
@import url("https://fonts.googleapis.com/css?family=Audiowide|Roboto+Condensed:400,700|Roboto+Slab:400,700&display=swap&subset=cyrillic");
@import url("https://fonts.googleapis.com/css?family=Syncopate:400,700&display=swap");

html {
  display: flex;
  flex-direction: column;
  height: auto;
  font-family: "Roboto Slab", serif;
  line-height: 1.2;
}

body {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 4px;
}
::-webkit-scrollbar-button {
  display: none;
}
