$A: "Audiowide", cursive;
$RCond: "Roboto Condensed", sans-serif;
$RSlab: "Roboto Slab", serif;
$darkHover: #1918239f;
$darkRedGradient: linear-gradient(to bottom, #ff2929 0%, #ffbc6b 100%);
$darkBlueGradient: linear-gradient(to bottom, #00b7ff 0%, #5be5b7 70%, #57d15d 100%);
$darkBg: #191823;
$halfDarkBg: #26263d;
$lightBg: linear-gradient(to bottom, #ededed71 0%, #b8b8b8 100%);
@mixin defBtn {
  span {
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
  }
}
@import "./themes/dialogsOverlays.scss";
@import "./themes/scrollBar.scss";
body {
  .defaulForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px 30px 30px;
    .defaulForm__input {
      width: 100%;
    }
  }
  $colors: "dark-red" "#ff2929d0 0%, #ffbc6bce 100%", "dark-blue" "#00b7ffc9 0%, #5be5b7c2 70%, #57d15dbb 100%",
    "dark-pink" "#6b01ffc7 0%, #f2b0ffc2 100%", "light-blue" "#2f4579a9 0%, #05070ccc 100%",
    "light-green" "#33928dc9 20%, #20403ed2 100%", "light-grey" "rgba(0, 0, 0, 0.788) 0%, #6c6c6cc4 100%";
  @each $color, $gradient in $colors {
    &.#{$color} {
      $bgs: "bg1", "bg2", "bg3", "bg4";
      @each $bg in $bgs {
        &.#{$bg} {
          .mainLayout {
            .sideMenu {
              background: linear-gradient(to bottom, #{$gradient}), url("assets/#{$bg}_big.png") no-repeat center/cover;
            }
          }
        }
      }
    }
  }
  @import "./themes/darkRed";
  @import "./themes/darkBlue";
  @import "./themes/darkPink";
  @import "./themes/lightBlue";
  @import "./themes/lightGreen";
  @import "./themes/lightGrey";
}
@keyframes visible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@import "./themes/dialogDefault";
