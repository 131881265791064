.dialog-ctLogin .MuiDialog-paperFullScreen,
.dialog-confirmEmail .MuiDialog-paperFullScreen {
  background: url("../assets/imgs/ct_login_bg.jpg") no-repeat center/cover !important;
}
.dialog-register .MuiDialog-paperFullScreen {
  background: url("../assets/imgs/doctorReg_bg.jpg") no-repeat center/cover !important;
}
.simpleDialog .MuiDialog-paperFullScreen {
  background: #ebebeb !important;
}
.dialog-online .MuiDialog-paperFullScreen {
  background: #aaaaaa !important;
}
.dialog-recommend .MuiDialog-paperFullScreen {
  background: #aaaaaa !important;
}
