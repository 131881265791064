/* Scroll OSLike FF IE unsupport */
::-webkit-scrollbar {
  background-color: transparent;
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #cfd8dc;
  border-radius: 4px;
}
