$A: "Audiowide", cursive;
$RCond: "Roboto Condensed", sans-serif;
$RSlab: "Roboto Slab", serif;
$darkHover: #1918239f;
$darkRedGradient: linear-gradient(to bottom, #ff2929 0%, #ffbc6b 100%);
$darkBlueGradient: linear-gradient(to bottom, #00b7ff 0%, #5be5b7 70%, #57d15d 100%);
$darkBg: #191823;
$halfDarkBg: #26263d;
$lightBg: linear-gradient(to bottom, #ededed71 0%, #b8b8b8 100%);
&.dark-red {
  background: $darkBg;
  .previewFile {
    a {
      text-decoration: none;
    }
    .container {
      padding: 0 20px;
    }
    .header {
      background: $halfDarkBg;
      border-bottom: 1px solid $darkBg;
      .logo {
        h5 {
          color: #fff;
        }
        h6 {
          color: #bdbdbd;
        }
      }
      .back {
        .defaultBtn {
          span {
            color: #fff;
          }
        }
      }
    }
    .item {
      background: $halfDarkBg;
      color: #fff;
      .head {
        border-bottom: 1px solid #616161;
      }
      .body {
        .btn {
          background: linear-gradient(to bottom, #9ef46b 0%, #0e3d1b 100%);
          span {
            color: #fff;
          }
        }
      }
    }
  }
  .mainLayout {
    background: $darkBg;
    color: #fff;
    .cards {
      .cards__item {
        background: $halfDarkBg;
        color: #fff;
        .cards__content {
          border-top: 1px solid #616161;
          .cards__info {
            .cards__btns {
              .cards__btn {
                background: #d3fdba;
              }
            }
          }
        }
      }
    }
    .defaultBtn {
      background: linear-gradient(to bottom, #9ef46b 0%, #0e3d1b 100%);
      @include defBtn;
    }
    .topBar {
      background: $halfDarkBg;
    }
    .sideMenu {
      .sideMenu__arrow {
        background: linear-gradient(to bottom, #ff2929 0%, #ffbc6b 100%);
      }
      .sideMenu-itemLink {
        &.active {
          background: $darkHover;
        }
        &:hover {
          background: $darkHover;
        }
      }
      .sideMenu-listContact {
        .sideMenu-listContact-name {
          &.active {
            background: $darkHover;
          }
          &:hover {
            background: $darkHover;
          }
        }
      }
    }
    .profileContent {
      .profileContent__box {
        background: $halfDarkBg;

        &_company {
          background: $darkRedGradient;
        }
        .profileContent__btn {
          color: #fff;
        }
      }
    }
    .contactsContent-wrapper {
      .table {
        .head {
          background: $halfDarkBg;
        }
        .body {
          background: linear-gradient(to bottom, #ff6145 0%, #2ea470 100%);
          .row {
            .cell {
              .toPatients {
                background: #d3fdba;
              }
            }
          }
        }
      }
    }
  }
  .dialog-btn {
    background: linear-gradient(to bottom, #9ef46b 0%, #0e3d1b 100%);
  }
  .dialog-default {
    .dialog__wrap {
      .dialog-defaultTitle {
        background: linear-gradient(to bottom, #ff2929 0%, #ffbc6b 100%);
        color: #fff;
      }
    }
  }
}
