.dialog-default {
  .MuiPaper-root {
    background: none !important;
    box-shadow: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
  .dialog__wrap {
    margin: 50px;
    width: 100%;
    padding-top: 60px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.13);
    position: relative;
    .dialog-defaultTitle {
      position: absolute;
      border-radius: 5px;
      font-size: 24px;
      font-weight: 700;
      padding: 30px 60px;
      top: -30px;
      left: 20px;
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.219);
      @media screen and (max-width: 500px) {
        top: -10px;
        padding: 10px;
        font-size: 20px;
      }
    }
    .defaultPaper {
      box-shadow: none !important;
      transition: 0.5s;
    }
  }
  .dialog__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    &_center {
      justify-content: center;
      margin-top: 0;
      button {
        margin-right: 20px;
        span {
          color: #fff;
        }
      }
    }
  }
}
.dialog-btn {
  max-width: 125px;
  width: 100%;
  span {
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
  }
}
