@import url(https://fonts.googleapis.com/css?family=Audiowide|Roboto+Condensed:400,700|Roboto+Slab:400,700&display=swap&subset=cyrillic);
@import url(https://fonts.googleapis.com/css?family=Syncopate:400,700&display=swap);
.dialog-ctLogin .MuiDialog-paperFullScreen,
.dialog-confirmEmail .MuiDialog-paperFullScreen {
  background: url(/static/media/ct_login_bg.d15afc7f.jpg) no-repeat center/cover !important; }

.dialog-register .MuiDialog-paperFullScreen {
  background: url(/static/media/doctorReg_bg.c76bc988.jpg) no-repeat center/cover !important; }

.simpleDialog .MuiDialog-paperFullScreen {
  background: #ebebeb !important; }

.dialog-online .MuiDialog-paperFullScreen {
  background: #aaaaaa !important; }

.dialog-recommend .MuiDialog-paperFullScreen {
  background: #aaaaaa !important; }

/* Scroll OSLike FF IE unsupport */
::-webkit-scrollbar {
  background-color: transparent;
  width: 4px; }

::-webkit-scrollbar-track {
  background-color: transparent; }

::-webkit-scrollbar-thumb {
  background-color: #cfd8dc;
  border-radius: 4px; }

body .defaulForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px 30px 30px; }
  body .defaulForm .defaulForm__input {
    width: 100%; }

body.dark-red.bg1 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#ff2929d0), to(#ffbc6bce)), url(/static/media/bg1_big.a643a7db.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #ff2929d0 0%, #ffbc6bce 100%), url(/static/media/bg1_big.a643a7db.png) no-repeat center/cover; }

body.dark-red.bg2 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#ff2929d0), to(#ffbc6bce)), url(/static/media/bg2_big.cc279fed.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #ff2929d0 0%, #ffbc6bce 100%), url(/static/media/bg2_big.cc279fed.png) no-repeat center/cover; }

body.dark-red.bg3 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#ff2929d0), to(#ffbc6bce)), url(/static/media/bg3_big.0309659b.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #ff2929d0 0%, #ffbc6bce 100%), url(/static/media/bg3_big.0309659b.png) no-repeat center/cover; }

body.dark-red.bg4 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#ff2929d0), to(#ffbc6bce)), url(/static/media/bg4_big.912196cd.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #ff2929d0 0%, #ffbc6bce 100%), url(/static/media/bg4_big.912196cd.png) no-repeat center/cover; }

body.dark-blue.bg1 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#00b7ffc9), color-stop(70%, #5be5b7c2), to(#57d15dbb)), url(/static/media/bg1_big.a643a7db.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #00b7ffc9 0%, #5be5b7c2 70%, #57d15dbb 100%), url(/static/media/bg1_big.a643a7db.png) no-repeat center/cover; }

body.dark-blue.bg2 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#00b7ffc9), color-stop(70%, #5be5b7c2), to(#57d15dbb)), url(/static/media/bg2_big.cc279fed.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #00b7ffc9 0%, #5be5b7c2 70%, #57d15dbb 100%), url(/static/media/bg2_big.cc279fed.png) no-repeat center/cover; }

body.dark-blue.bg3 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#00b7ffc9), color-stop(70%, #5be5b7c2), to(#57d15dbb)), url(/static/media/bg3_big.0309659b.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #00b7ffc9 0%, #5be5b7c2 70%, #57d15dbb 100%), url(/static/media/bg3_big.0309659b.png) no-repeat center/cover; }

body.dark-blue.bg4 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#00b7ffc9), color-stop(70%, #5be5b7c2), to(#57d15dbb)), url(/static/media/bg4_big.912196cd.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #00b7ffc9 0%, #5be5b7c2 70%, #57d15dbb 100%), url(/static/media/bg4_big.912196cd.png) no-repeat center/cover; }

body.dark-pink.bg1 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#6b01ffc7), to(#f2b0ffc2)), url(/static/media/bg1_big.a643a7db.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #6b01ffc7 0%, #f2b0ffc2 100%), url(/static/media/bg1_big.a643a7db.png) no-repeat center/cover; }

body.dark-pink.bg2 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#6b01ffc7), to(#f2b0ffc2)), url(/static/media/bg2_big.cc279fed.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #6b01ffc7 0%, #f2b0ffc2 100%), url(/static/media/bg2_big.cc279fed.png) no-repeat center/cover; }

body.dark-pink.bg3 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#6b01ffc7), to(#f2b0ffc2)), url(/static/media/bg3_big.0309659b.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #6b01ffc7 0%, #f2b0ffc2 100%), url(/static/media/bg3_big.0309659b.png) no-repeat center/cover; }

body.dark-pink.bg4 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#6b01ffc7), to(#f2b0ffc2)), url(/static/media/bg4_big.912196cd.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #6b01ffc7 0%, #f2b0ffc2 100%), url(/static/media/bg4_big.912196cd.png) no-repeat center/cover; }

body.light-blue.bg1 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#2f4579a9), to(#05070ccc)), url(/static/media/bg1_big.a643a7db.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #2f4579a9 0%, #05070ccc 100%), url(/static/media/bg1_big.a643a7db.png) no-repeat center/cover; }

body.light-blue.bg2 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#2f4579a9), to(#05070ccc)), url(/static/media/bg2_big.cc279fed.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #2f4579a9 0%, #05070ccc 100%), url(/static/media/bg2_big.cc279fed.png) no-repeat center/cover; }

body.light-blue.bg3 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#2f4579a9), to(#05070ccc)), url(/static/media/bg3_big.0309659b.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #2f4579a9 0%, #05070ccc 100%), url(/static/media/bg3_big.0309659b.png) no-repeat center/cover; }

body.light-blue.bg4 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(#2f4579a9), to(#05070ccc)), url(/static/media/bg4_big.912196cd.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #2f4579a9 0%, #05070ccc 100%), url(/static/media/bg4_big.912196cd.png) no-repeat center/cover; }

body.light-green.bg1 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #33928dc9), to(#20403ed2)), url(/static/media/bg1_big.a643a7db.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #33928dc9 20%, #20403ed2 100%), url(/static/media/bg1_big.a643a7db.png) no-repeat center/cover; }

body.light-green.bg2 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #33928dc9), to(#20403ed2)), url(/static/media/bg2_big.cc279fed.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #33928dc9 20%, #20403ed2 100%), url(/static/media/bg2_big.cc279fed.png) no-repeat center/cover; }

body.light-green.bg3 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #33928dc9), to(#20403ed2)), url(/static/media/bg3_big.0309659b.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #33928dc9 20%, #20403ed2 100%), url(/static/media/bg3_big.0309659b.png) no-repeat center/cover; }

body.light-green.bg4 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #33928dc9), to(#20403ed2)), url(/static/media/bg4_big.912196cd.png) no-repeat center/cover;
  background: linear-gradient(to bottom, #33928dc9 20%, #20403ed2 100%), url(/static/media/bg4_big.912196cd.png) no-repeat center/cover; }

body.light-grey.bg1 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.788)), to(#6c6c6cc4)), url(/static/media/bg1_big.a643a7db.png) no-repeat center/cover;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.788) 0%, #6c6c6cc4 100%), url(/static/media/bg1_big.a643a7db.png) no-repeat center/cover; }

body.light-grey.bg2 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.788)), to(#6c6c6cc4)), url(/static/media/bg2_big.cc279fed.png) no-repeat center/cover;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.788) 0%, #6c6c6cc4 100%), url(/static/media/bg2_big.cc279fed.png) no-repeat center/cover; }

body.light-grey.bg3 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.788)), to(#6c6c6cc4)), url(/static/media/bg3_big.0309659b.png) no-repeat center/cover;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.788) 0%, #6c6c6cc4 100%), url(/static/media/bg3_big.0309659b.png) no-repeat center/cover; }

body.light-grey.bg4 .mainLayout .sideMenu {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.788)), to(#6c6c6cc4)), url(/static/media/bg4_big.912196cd.png) no-repeat center/cover;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.788) 0%, #6c6c6cc4 100%), url(/static/media/bg4_big.912196cd.png) no-repeat center/cover; }

body.dark-red {
  background: #191823; }
  body.dark-red .previewFile a {
    text-decoration: none; }
  body.dark-red .previewFile .container {
    padding: 0 20px; }
  body.dark-red .previewFile .header {
    background: #26263d;
    border-bottom: 1px solid #191823; }
    body.dark-red .previewFile .header .logo h5 {
      color: #fff; }
    body.dark-red .previewFile .header .logo h6 {
      color: #bdbdbd; }
    body.dark-red .previewFile .header .back .defaultBtn span {
      color: #fff; }
  body.dark-red .previewFile .item {
    background: #26263d;
    color: #fff; }
    body.dark-red .previewFile .item .head {
      border-bottom: 1px solid #616161; }
    body.dark-red .previewFile .item .body .btn {
      background: -webkit-gradient(linear, left top, left bottom, from(#9ef46b), to(#0e3d1b));
      background: linear-gradient(to bottom, #9ef46b 0%, #0e3d1b 100%); }
      body.dark-red .previewFile .item .body .btn span {
        color: #fff; }
  body.dark-red .mainLayout {
    background: #191823;
    color: #fff; }
    body.dark-red .mainLayout .cards .cards__item {
      background: #26263d;
      color: #fff; }
      body.dark-red .mainLayout .cards .cards__item .cards__content {
        border-top: 1px solid #616161; }
        body.dark-red .mainLayout .cards .cards__item .cards__content .cards__info .cards__btns .cards__btn {
          background: #d3fdba; }
    body.dark-red .mainLayout .defaultBtn {
      background: -webkit-gradient(linear, left top, left bottom, from(#9ef46b), to(#0e3d1b));
      background: linear-gradient(to bottom, #9ef46b 0%, #0e3d1b 100%); }
      body.dark-red .mainLayout .defaultBtn span {
        font-size: 14px;
        text-transform: uppercase;
        color: #fff; }
    body.dark-red .mainLayout .topBar {
      background: #26263d; }
    body.dark-red .mainLayout .sideMenu .sideMenu__arrow {
      background: -webkit-gradient(linear, left top, left bottom, from(#ff2929), to(#ffbc6b));
      background: linear-gradient(to bottom, #ff2929 0%, #ffbc6b 100%); }
    body.dark-red .mainLayout .sideMenu .sideMenu-itemLink.active {
      background: #1918239f; }
    body.dark-red .mainLayout .sideMenu .sideMenu-itemLink:hover {
      background: #1918239f; }
    body.dark-red .mainLayout .sideMenu .sideMenu-listContact .sideMenu-listContact-name.active {
      background: #1918239f; }
    body.dark-red .mainLayout .sideMenu .sideMenu-listContact .sideMenu-listContact-name:hover {
      background: #1918239f; }
    body.dark-red .mainLayout .profileContent .profileContent__box {
      background: #26263d; }
      body.dark-red .mainLayout .profileContent .profileContent__box_company {
        background: -webkit-gradient(linear, left top, left bottom, from(#ff2929), to(#ffbc6b));
        background: linear-gradient(to bottom, #ff2929 0%, #ffbc6b 100%); }
      body.dark-red .mainLayout .profileContent .profileContent__box .profileContent__btn {
        color: #fff; }
    body.dark-red .mainLayout .contactsContent-wrapper .table .head {
      background: #26263d; }
    body.dark-red .mainLayout .contactsContent-wrapper .table .body {
      background: -webkit-gradient(linear, left top, left bottom, from(#ff6145), to(#2ea470));
      background: linear-gradient(to bottom, #ff6145 0%, #2ea470 100%); }
      body.dark-red .mainLayout .contactsContent-wrapper .table .body .row .cell .toPatients {
        background: #d3fdba; }
  body.dark-red .dialog-btn {
    background: -webkit-gradient(linear, left top, left bottom, from(#9ef46b), to(#0e3d1b));
    background: linear-gradient(to bottom, #9ef46b 0%, #0e3d1b 100%); }
  body.dark-red .dialog-default .dialog__wrap .dialog-defaultTitle {
    background: -webkit-gradient(linear, left top, left bottom, from(#ff2929), to(#ffbc6b));
    background: linear-gradient(to bottom, #ff2929 0%, #ffbc6b 100%);
    color: #fff; }

body.dark-blue {
  background: #191823; }
  body.dark-blue .previewFile a {
    text-decoration: none; }
  body.dark-blue .previewFile .container {
    padding: 0 20px; }
  body.dark-blue .previewFile .header {
    background: #26263d;
    border-bottom: 1px solid #191823; }
    body.dark-blue .previewFile .header .logo h5 {
      color: #fff; }
    body.dark-blue .previewFile .header .logo h6 {
      color: #bdbdbd; }
    body.dark-blue .previewFile .header .back .defaultBtn span {
      color: #fff; }
  body.dark-blue .previewFile .item {
    background: #26263d;
    color: #fff; }
    body.dark-blue .previewFile .item .head {
      border-bottom: 1px solid #616161; }
    body.dark-blue .previewFile .item .body .btn {
      background: -webkit-gradient(linear, left top, left bottom, from(#ffae00), to(#f1673e));
      background: linear-gradient(to bottom, #ffae00 0%, #f1673e 100%); }
      body.dark-blue .previewFile .item .body .btn span {
        color: #fff; }
  body.dark-blue .mainLayout {
    background: #191823;
    color: #fff; }
    body.dark-blue .mainLayout .cards .cards__item {
      background: #26263d;
      color: #fff; }
      body.dark-blue .mainLayout .cards .cards__item .cards__content {
        border-top: 1px solid #616161; }
        body.dark-blue .mainLayout .cards .cards__item .cards__content .cards__info .cards__btns .cards__btn {
          background: #d3fdba; }
    body.dark-blue .mainLayout .defaultBtn {
      background: -webkit-gradient(linear, left top, left bottom, from(#ffae00), to(#f1673e));
      background: linear-gradient(to bottom, #ffae00 0%, #f1673e 100%); }
      body.dark-blue .mainLayout .defaultBtn span {
        font-size: 14px;
        text-transform: uppercase;
        color: #fff; }
    body.dark-blue .mainLayout .topBar {
      background: #26263d; }
    body.dark-blue .mainLayout .sideMenu .sideMenu__arrow {
      background: -webkit-gradient(linear, left top, left bottom, from(#00b7ff), color-stop(70%, #5be5b7), to(#57d15d));
      background: linear-gradient(to bottom, #00b7ff 0%, #5be5b7 70%, #57d15d 100%); }
    body.dark-blue .mainLayout .sideMenu .sideMenu-itemLink.active {
      background: #1918239f; }
    body.dark-blue .mainLayout .sideMenu .sideMenu-itemLink:hover {
      background: #1918239f; }
    body.dark-blue .mainLayout .sideMenu .sideMenu-listContact .sideMenu-listContact-name.active {
      background: #1918239f; }
    body.dark-blue .mainLayout .sideMenu .sideMenu-listContact .sideMenu-listContact-name:hover {
      background: #1918239f; }
    body.dark-blue .mainLayout .profileContent .profileContent__box {
      background: #26263d; }
      body.dark-blue .mainLayout .profileContent .profileContent__box_company {
        background: -webkit-gradient(linear, left top, left bottom, from(#00b7ff), color-stop(70%, #5be5b7), to(#57d15d));
        background: linear-gradient(to bottom, #00b7ff 0%, #5be5b7 70%, #57d15d 100%); }
      body.dark-blue .mainLayout .profileContent .profileContent__box .profileContent__btn {
        color: #fff; }
    body.dark-blue .mainLayout .contactsContent-wrapper .table .head {
      background: #26263d; }
    body.dark-blue .mainLayout .contactsContent-wrapper .table .body {
      background: -webkit-gradient(linear, left top, left bottom, from(#34bf49), to(#f1673e));
      background: linear-gradient(to bottom, #34bf49 0%, #f1673e 100%); }
      body.dark-blue .mainLayout .contactsContent-wrapper .table .body .row .cell .toPatients {
        background: #ffecb3; }
  body.dark-blue .dialog-btn {
    background: -webkit-gradient(linear, left top, left bottom, from(#ffae00), to(#f1673e));
    background: linear-gradient(to bottom, #ffae00 0%, #f1673e 100%); }

body .dialog-default .dialog__wrap .dialog-defaultTitle {
  background: -webkit-gradient(linear, left top, left bottom, from(#00b7ff), to(#57d15d));
  background: linear-gradient(to bottom, #00b7ff 0%, #57d15d 100%);
  color: #fff; }

body.dark-pink {
  background: #191823; }
  body.dark-pink .previewFile a {
    text-decoration: none; }
  body.dark-pink .previewFile .container {
    padding: 0 20px; }
  body.dark-pink .previewFile .header {
    background: #26263d;
    border-bottom: 1px solid #191823; }
    body.dark-pink .previewFile .header .logo h5 {
      color: #fff; }
    body.dark-pink .previewFile .header .logo h6 {
      color: #bdbdbd; }
    body.dark-pink .previewFile .header .back .defaultBtn span {
      color: #fff; }
  body.dark-pink .previewFile .item {
    background: #26263d;
    color: #fff; }
    body.dark-pink .previewFile .item .head {
      border-bottom: 1px solid #616161; }
    body.dark-pink .previewFile .item .body .btn {
      background: -webkit-gradient(linear, left top, left bottom, from(#ffae00), to(#f1673e));
      background: linear-gradient(to bottom, #ffae00 0%, #f1673e 100%); }
      body.dark-pink .previewFile .item .body .btn span {
        color: #fff; }
  body.dark-pink .mainLayout {
    background: #191823;
    color: #fff; }
    body.dark-pink .mainLayout .cards .cards__item {
      background: #26263d;
      color: #fff; }
      body.dark-pink .mainLayout .cards .cards__item .cards__content {
        border-top: 1px solid #616161; }
        body.dark-pink .mainLayout .cards .cards__item .cards__content .cards__info .cards__btns .cards__btn {
          background: #d3fdba; }
    body.dark-pink .mainLayout .defaultBtn {
      background: -webkit-gradient(linear, left top, left bottom, from(#ffae00), to(#f1673e));
      background: linear-gradient(to bottom, #ffae00 0%, #f1673e 100%); }
      body.dark-pink .mainLayout .defaultBtn span {
        font-size: 14px;
        text-transform: uppercase;
        color: #fff; }
    body.dark-pink .mainLayout .topBar {
      background: #26263d; }
    body.dark-pink .mainLayout .sideMenu .sideMenu__arrow {
      background: -webkit-gradient(linear, left top, left bottom, from(#6a01ff), to(#f2b0ff));
      background: linear-gradient(to bottom, #6a01ff 0%, #f2b0ff 100%); }
    body.dark-pink .mainLayout .sideMenu .sideMenu-itemLink.active {
      background: #1918239f; }
    body.dark-pink .mainLayout .sideMenu .sideMenu-itemLink:hover {
      background: #1918239f; }
    body.dark-pink .mainLayout .sideMenu .sideMenu-listContact .sideMenu-listContact-name.active {
      background: #1918239f; }
    body.dark-pink .mainLayout .sideMenu .sideMenu-listContact .sideMenu-listContact-name:hover {
      background: #1918239f; }
    body.dark-pink .mainLayout .profileContent .profileContent__box {
      background: #26263d; }
      body.dark-pink .mainLayout .profileContent .profileContent__box_company {
        background: -webkit-gradient(linear, left top, left bottom, from(#6a01ff), to(#f2b0ff));
        background: linear-gradient(to bottom, #6a01ff 0%, #f2b0ff 100%); }
      body.dark-pink .mainLayout .profileContent .profileContent__box .profileContent__btn {
        color: #fff; }
    body.dark-pink .mainLayout .contactsContent-wrapper .table .head {
      background: #26263d; }
    body.dark-pink .mainLayout .contactsContent-wrapper .table .body {
      background: -webkit-gradient(linear, left top, left bottom, from(#5500ff), to(#f1673e));
      background: linear-gradient(to bottom, #5500ff 0%, #f1673e 100%); }
      body.dark-pink .mainLayout .contactsContent-wrapper .table .body .row .cell .toPatients {
        background: #ffb74d; }
  body.dark-pink .dialog-btn {
    background: -webkit-gradient(linear, left top, left bottom, from(#ffae00), to(#f1673e));
    background: linear-gradient(to bottom, #ffae00 0%, #f1673e 100%); }

body .dialog-default .dialog__wrap .dialog-defaultTitle {
  background: -webkit-gradient(linear, left top, left bottom, from(#6a01ff), to(#f2b0ff));
  background: linear-gradient(to bottom, #6a01ff 0%, #f2b0ff 100%);
  color: #fff; }

body.light-blue {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #ededed83), to(#b8b8b8));
  background: linear-gradient(to bottom, #ededed83 30%, #b8b8b8 100%); }
  body.light-blue .previewFile a {
    text-decoration: none; }
  body.light-blue .previewFile .container {
    padding: 0 20px; }
  body.light-blue .previewFile .header {
    background: #26263d;
    border-bottom: 1px solid #191823; }
    body.light-blue .previewFile .header .logo h5 {
      color: #fff; }
    body.light-blue .previewFile .header .logo h6 {
      color: #bdbdbd; }
    body.light-blue .previewFile .header .back .defaultBtn span {
      color: #fff; }
  body.light-blue .previewFile .item {
    background: #26263d;
    color: #fff; }
    body.light-blue .previewFile .item .head {
      border-bottom: 1px solid #616161; }
    body.light-blue .previewFile .item .body .btn {
      background: -webkit-gradient(linear, left top, left bottom, from(#ffae00), to(#f1673e));
      background: linear-gradient(to bottom, #ffae00 0%, #f1673e 100%); }
      body.light-blue .previewFile .item .body .btn span {
        color: #fff; }
  body.light-blue .mainLayout {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #ededed83), to(#b8b8b8));
    background: linear-gradient(to bottom, #ededed83 30%, #b8b8b8 100%);
    color: #232323; }
    body.light-blue .mainLayout .cards .cards__item {
      background: #fff;
      color: #000; }
      body.light-blue .mainLayout .cards .cards__item .cards__content {
        border-top: 1px solid #616161; }
        body.light-blue .mainLayout .cards .cards__item .cards__content .cards__info .cards__btns .cards__btn {
          background: #f3e5f5; }
    body.light-blue .mainLayout .defaultBtn {
      background: #00bcd4; }
      body.light-blue .mainLayout .defaultBtn span {
        font-size: 14px;
        text-transform: uppercase;
        color: #fff; }
    body.light-blue .mainLayout .topBar {
      background: #1e88e5;
      color: #fff; }
    body.light-blue .mainLayout .sideMenu .sideMenu__arrow {
      background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #5577c083), to(#b8b8b8));
      background: linear-gradient(to bottom, #5577c083 30%, #b8b8b8 100%); }
    body.light-blue .mainLayout .sideMenu .sideMenu-itemLink.active {
      background: #26c6da; }
    body.light-blue .mainLayout .sideMenu .sideMenu-itemLink:hover {
      background: #26c6da; }
    body.light-blue .mainLayout .sideMenu .sideMenu-listContact .sideMenu-listContact-name.active {
      background: #26c6da; }
    body.light-blue .mainLayout .sideMenu .sideMenu-listContact .sideMenu-listContact-name:hover {
      background: #26c6da; }
    body.light-blue .mainLayout .profileContent .profileContent__box {
      background: #fff; }
      body.light-blue .mainLayout .profileContent .profileContent__box_company {
        background: #fff; }
      body.light-blue .mainLayout .profileContent .profileContent__box .profileContent__btn {
        color: #000; }
    body.light-blue .mainLayout .companyList .companyList__wrap .companyList__item .companyList__create {
      background: #00bcd4;
      border: none; }
    body.light-blue .mainLayout .contactsContent-wrapper .table .head {
      background: #f7f6f6; }
    body.light-blue .mainLayout .contactsContent-wrapper .table .body {
      background: #fff; }
      body.light-blue .mainLayout .contactsContent-wrapper .table .body .row .cell .toPatients {
        background: #e8eaf6; }
  body.light-blue .dialog-btn {
    background: #00bcd4; }
  body.light-blue .dialog-default .dialog__wrap .dialog-defaultTitle {
    background: #039be5;
    color: #fff; }

body.light-green {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #ededed83), to(#b8b8b8));
  background: linear-gradient(to bottom, #ededed83 30%, #b8b8b8 100%); }
  body.light-green .topBar .topBar-options-container .title {
    color: #fff; }
  body.light-green .previewFile a {
    text-decoration: none; }
  body.light-green .previewFile .container {
    padding: 0 20px; }
  body.light-green .previewFile .header {
    background: #26263d;
    border-bottom: 1px solid #191823; }
    body.light-green .previewFile .header .logo h5 {
      color: #fff; }
    body.light-green .previewFile .header .logo h6 {
      color: #bdbdbd; }
    body.light-green .previewFile .header .back .defaultBtn span {
      color: #fff; }
  body.light-green .previewFile .item {
    background: #26263d;
    color: #fff; }
    body.light-green .previewFile .item .head {
      border-bottom: 1px solid #616161; }
    body.light-green .previewFile .item .body .btn {
      background: -webkit-gradient(linear, left top, left bottom, from(#ffae00), to(#f1673e));
      background: linear-gradient(to bottom, #ffae00 0%, #f1673e 100%); }
      body.light-green .previewFile .item .body .btn span {
        color: #fff; }
  body.light-green .mainLayout {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #ededed83), to(#b8b8b8));
    background: linear-gradient(to bottom, #ededed83 30%, #b8b8b8 100%);
    color: #232323; }
    body.light-green .mainLayout .cards .cards__item {
      background: #fff;
      color: #000; }
      body.light-green .mainLayout .cards .cards__item .cards__content {
        border-top: 1px solid #616161; }
        body.light-green .mainLayout .cards .cards__item .cards__content .cards__info .cards__btns .cards__btn {
          background: #f3e5f5; }
    body.light-green .mainLayout .defaultBtn {
      background: #26c6da; }
      body.light-green .mainLayout .defaultBtn span {
        font-size: 14px;
        text-transform: uppercase;
        color: #fff; }
    body.light-green .mainLayout .topBar {
      background: #00bcd4; }
    body.light-green .mainLayout .sideMenu .sideMenu__arrow {
      background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #8eda8b83), to(#b8b8b8));
      background: linear-gradient(to bottom, #8eda8b83 30%, #b8b8b8 100%); }
    body.light-green .mainLayout .sideMenu .sideMenu-itemLink.active {
      background: #4db6ac; }
    body.light-green .mainLayout .sideMenu .sideMenu-itemLink:hover {
      background: #4db6ac; }
    body.light-green .mainLayout .sideMenu .sideMenu-listContact .sideMenu-listContact-name.active {
      background: #4db6ac; }
    body.light-green .mainLayout .sideMenu .sideMenu-listContact .sideMenu-listContact-name:hover {
      background: #4db6ac; }
    body.light-green .mainLayout .profileContent .profileContent__box {
      background: #fff; }
      body.light-green .mainLayout .profileContent .profileContent__box_company {
        background: #fff; }
      body.light-green .mainLayout .profileContent .profileContent__box .profileContent__btn {
        color: #000; }
    body.light-green .mainLayout .companyList .companyList__wrap .companyList__item .companyList__create {
      background: #00bcd4;
      border: none; }
    body.light-green .mainLayout .contactsContent-wrapper .table .head {
      background: #f7f6f6; }
    body.light-green .mainLayout .contactsContent-wrapper .table .body {
      background: #fff; }
      body.light-green .mainLayout .contactsContent-wrapper .table .body .row .cell .toPatients {
        background: #f3e5f5; }
  body.light-green .dialog-btn {
    background: #ff6f00; }
  body.light-green .dialog-default .dialog__wrap .dialog-defaultTitle {
    background: #00acc1;
    color: #fff; }

body.light-grey {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #ededed83), to(#b8b8b8));
  background: linear-gradient(to bottom, #ededed83 30%, #b8b8b8 100%); }
  body.light-grey .previewFile a {
    text-decoration: none; }
  body.light-grey .previewFile .container {
    padding: 0 20px; }
  body.light-grey .previewFile .header {
    background: #26263d;
    border-bottom: 1px solid #191823; }
    body.light-grey .previewFile .header .logo h5 {
      color: #fff; }
    body.light-grey .previewFile .header .logo h6 {
      color: #bdbdbd; }
    body.light-grey .previewFile .header .back .defaultBtn span {
      color: #fff; }
  body.light-grey .previewFile .item {
    background: #26263d;
    color: #fff; }
    body.light-grey .previewFile .item .head {
      border-bottom: 1px solid #616161; }
    body.light-grey .previewFile .item .body .btn {
      background: -webkit-gradient(linear, left top, left bottom, from(#ffae00), to(#f1673e));
      background: linear-gradient(to bottom, #ffae00 0%, #f1673e 100%); }
      body.light-grey .previewFile .item .body .btn span {
        color: #fff; }
  body.light-grey .mainLayout {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #ededed83), to(#b8b8b8));
    background: linear-gradient(to bottom, #ededed83 30%, #b8b8b8 100%);
    color: #232323; }
    body.light-grey .mainLayout .cards .cards__item {
      background: #fff;
      color: #000; }
      body.light-grey .mainLayout .cards .cards__item .cards__content {
        border-top: 1px solid #616161; }
        body.light-grey .mainLayout .cards .cards__item .cards__content .cards__info .cards__btns .cards__btn {
          background: #f3e5f5; }
    body.light-grey .mainLayout .defaultBtn {
      background: #ce93d8; }
      body.light-grey .mainLayout .defaultBtn span {
        font-size: 14px;
        text-transform: uppercase;
        color: #fff; }
    body.light-grey .mainLayout .topBar {
      background: #f7f6f6; }
    body.light-grey .mainLayout .sideMenu .sideMenu__arrow {
      background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #ededed83), to(#b8b8b8));
      background: linear-gradient(to bottom, #ededed83 30%, #b8b8b8 100%); }
    body.light-grey .mainLayout .sideMenu .sideMenu-itemLink.active {
      background: #e1bee7a9; }
    body.light-grey .mainLayout .sideMenu .sideMenu-itemLink:hover {
      background: #e1bee7a9; }
    body.light-grey .mainLayout .sideMenu .sideMenu-listContact .sideMenu-listContact-name.active {
      background: #e1bee7a9; }
    body.light-grey .mainLayout .sideMenu .sideMenu-listContact .sideMenu-listContact-name:hover {
      background: #e1bee7a9; }
    body.light-grey .mainLayout .profileContent .profileContent__box {
      background: #fff; }
      body.light-grey .mainLayout .profileContent .profileContent__box_company {
        background: #fff; }
      body.light-grey .mainLayout .profileContent .profileContent__box .profileContent__btn {
        color: #000; }
    body.light-grey .mainLayout .companyList .companyList__wrap .companyList__item .companyList__create {
      background: #ce93d8;
      border: none; }
    body.light-grey .mainLayout .contactsContent-wrapper .table .head {
      background: #f7f6f6; }
    body.light-grey .mainLayout .contactsContent-wrapper .table .body {
      background: #fff; }
      body.light-grey .mainLayout .contactsContent-wrapper .table .body .row .cell .toPatients {
        background: #f3e5f5; }
  body.light-grey .dialog-btn {
    background: #6a1b9a; }
  body.light-grey .dialog-default .dialog__wrap .dialog-defaultTitle {
    background: #ba68c8;
    color: #fff; }

@-webkit-keyframes visible {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes visible {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.dialog-default .MuiPaper-root {
  background: none !important;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center; }

.dialog-default .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.3) !important; }

.dialog-default .dialog__wrap {
  margin: 50px;
  width: 100%;
  padding-top: 60px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.13);
  position: relative; }
  .dialog-default .dialog__wrap .dialog-defaultTitle {
    position: absolute;
    border-radius: 5px;
    font-size: 24px;
    font-weight: 700;
    padding: 30px 60px;
    top: -30px;
    left: 20px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.219); }
    @media screen and (max-width: 500px) {
      .dialog-default .dialog__wrap .dialog-defaultTitle {
        top: -10px;
        padding: 10px;
        font-size: 20px; } }
  .dialog-default .dialog__wrap .defaultPaper {
    box-shadow: none !important;
    -webkit-transition: 0.5s;
    transition: 0.5s; }

.dialog-default .dialog__btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%; }
  .dialog-default .dialog__btns_center {
    justify-content: center;
    margin-top: 0; }
    .dialog-default .dialog__btns_center button {
      margin-right: 20px; }
      .dialog-default .dialog__btns_center button span {
        color: #fff; }

.dialog-btn {
  max-width: 125px;
  width: 100%; }
  .dialog-btn span {
    font-size: 14px;
    text-transform: uppercase;
    color: #fff; }

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  display: flex;
  flex-direction: column;
  height: auto;
  font-family: "Roboto Slab", serif;
  line-height: 1.2;
}

body {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 4px;
}
::-webkit-scrollbar-button {
  display: none;
}

